<template>
    <el-card class="box-card" id="contactType">
        <div slot="header" class="clearfix">
            <span style="font-weight: bold">Conversatii</span>
        </div>
        <div>
            <Conversation v-for="conv in conversations" :data="conv"></Conversation>
        </div>
    </el-card>
</template>
<script>
import { getOrderConversations } from '@/api/vanzari';
const Conversation = () => import('@/views/vanzari/comanda/conversations/Conversation.vue');

export default {
    props: ['oid'],
    components: {
        Conversation,
    },
    data() {
        return {
            conversations: [],
        };
    },
    methods: {
        async getOrderConversations() {
            try {
                const data = await getOrderConversations({ oid: this.oid });
                // Sort conversations by date
                data.message.sort((a, b) => new Date(b.date) - new Date(a.date));
                // And conversations replies too
                for (const [index, conv] of data.message.entries()) {
                    if (conv.replies) {
                        data.message[index].replies.sort((a, b) => new Date(b.date) - new Date(a.date));
                    }
                }
                this.conversations = data.message;
            } catch (e) {
                this.$reportToSentry(e, {
                    extra: {
                        fn: 'sendProforma',
                        params: {
                            orders_id: this.oid,
                        },
                    },
                });
            }
        },
    },
    beforeMount() {
        return this.getOrderConversations();
    },
};
</script>
